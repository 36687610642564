.project-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-card-container {
    border: 1px solid var(--lightblue);
    border-radius: 18px;
    width: 36vw;
    height: 34vh;
    background-color: var(--babyblue);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
}

.project-card-container:hover {
    animation: softBounce 3s infinite;
}

@keyframes softBounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-12px);
    }
}

.project-card-container img {
    width: 110%;
    height: 110%;
    object-fit: cover;
    object-position: center;
    border-radius: 18px;
}

.info {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 14px;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding-left: 8px;
}

.title {
    text-align: left;
    margin-left: 12px;
    font-size: 18px;
    font-weight: 500;
    color: var(--darkgrey);
    width: auto;
    text-decoration: none;
}

.tab {
    padding: 4px 8px;
    border-radius: 5px;
    display: inline-block;
    color: white;
}

.tab-light-blue {
    background-color: var(--lightblue);
}

.tab-dark-blue {
    background-color: var(--darkblue);
}

.tab-purple {
    background-color: var(--purple);
}

@media (max-width: 1200px) {
    .project-card-container {
        width: 55vw;
        height: 28vh;
    }

    .info {
        width: 55vw;
    }
}

@media (max-width: 768px) {
    .project-card-container {
        width: 55vw;
        height: 28vh;
    }

    .info {
        width: 55vw;
    }
}

@media (max-width: 768px) {
    .tab-light-blue, .tab-dark-blue, .tab-purple {
        display: none;
    }
}

.project-link {
    display: block;
    width: 100%;
    height: 100%;
}
