.project-container {
    margin-top: 10vh;
    margin-bottom: 10vh;
    padding-top: 50px;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.project-container.fade-in {
    opacity: 1;
    transform: translateY(0);
}

.projects {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    gap: 3rem 2rem;
    width: 100%;
}

@media (max-width: 1200px) {
    .projects {
        grid-template-columns: 1fr;
        justify-items: center;
    }
}

@media (max-width: 768px) {
    .projects {
        grid-template-columns: 1fr;
        justify-items: center;
    }
}
