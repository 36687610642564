.blog {
    font-style: italic;
}

.lightblue {
    color: var(--lightblue);
}

.darkblue {
    color: var(--darkblue);
}

.purple {
    color: var(--purple);
}

.lightblue, .darkblue, .purple {
    font-style: italic;
    font-weight: 600;
    font-size: 100px;
}

.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30vh;
    margin-bottom: 36vh;
    z-index: 1;
    margin-left: 10%;
    margin-right: 10%;
    gap: 50px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
}

.description {
    text-align: left;
    font-size: 20px;
    color: var(--darkgrey);
    margin-bottom: 52px;
}

.hero-image {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

@media (max-width: 1100px) {
    .container {
        flex-direction: column;
        margin-left: 10%;
        margin-right: 10%;
    }
    .hero-image {
        width: 60%;
    }
    .content {
        align-items: center;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        margin-left: 10%;
        margin-right: 10%;
    }
    .hero-image {
        width: 60%;
    }
    .content {
        align-items: center;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .container {
        flex-direction: column;
        margin-left: 10%;
        margin-right: 10%;
    }
    .hero-image {
        width: 100%;
    }
    .content {
        align-items: center;
        text-align: center;
    }
}

.hero-image:hover {
    animation: softBounce 3s infinite;
}

@media (min-width: 1500px) {
    .hero-image img {
        max-width: 400px;
    }
}